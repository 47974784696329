import icon1 from "@/assets/img/emoji/开心.png"
import icon2 from "@/assets/img/emoji/惬意.png"
import icon3 from "@/assets/img/emoji/捂脸.png"
import icon4 from "@/assets/img/emoji/平静.png"
import icon5 from "@/assets/img/emoji/加油.png"
import icon6 from "@/assets/img/emoji/心动.png"
import icon7 from "@/assets/img/emoji/哭泣.png"
import icon8 from "@/assets/img/emoji/生气.png"
import icon9 from "@/assets/img/emoji/疑问.png"
import icon10 from "@/assets/img/emoji/无语.png"
import icon11 from "@/assets/img/emoji/斜眼.png"
import icon12 from "@/assets/img/emoji/裂开.png"
import icon13 from "@/assets/img/emoji/脸绿.png"
import icon14 from "@/assets/img/emoji/委屈.png"
import icon15 from "@/assets/img/emoji/不开心.png"

let imgs = new Map()
function imgPreload (img) {
    return new Promise((resolve, reject) => {
        if (imgs.has(img)) {
            resolve(img)
            return
        }
        let image = new Image()
        image.onload = () => {
            imgs.set(img, true)
            resolve(img)
        }
        image.onerror = () => { reject() }
        image.src = img
    })
}

const emoji = async () => {
    return [
        {
            id: 1,
            name: "开心",
            type: "开心",
            icon: await imgPreload(icon1),
            describe: "做人，最重要的就是开心喽",
            onKey: "开心到起飞",
        },
        {
            id: 2,
            name: "惬意",
            type: "开心",
            icon: await imgPreload(icon2),
            describe: "真舒坦啊~",
            onKey: "真舒坦啊~",
        },
        {
            id: 3,
            icon: await imgPreload(icon3),
            name: "捂脸",
            type: "开心",
            describe: "咦~，没脸看",
            onKey: "没脸看",
        }, {
            id: 4,
            icon: await imgPreload(icon4),
            name: "平静",
            type: "一般",
            describe: "不算好，不算坏",
            onKey: "心如止水",
        },
        {
            id: 5,
            icon: await imgPreload(icon5),
            name: "加油",
            type: "一般",
            describe: "冲冲冲！",
            onKey: "一起加油鸭！",
        }, {
            id: 6,
            icon: await imgPreload(icon6),
            name: "心动",
            type: "开心",
            describe: "哇哦~，爱了爱了",
            onKey: "是心动的感觉",
        }, {
            id: 7,
            icon: await imgPreload(icon7),
            name: "哭泣",
            type: "不开心",
            describe: "呜呜~，爆哭",
            onKey: "暴风哭泣",
        }, {
            id: 8,
            icon: await imgPreload(icon8),
            name: "生气",
            type: "不开心",
            describe: "怎么可以这样啊",
            onKey: "啊啊啊~毁灭吧",
        }, {
            id: 9,
            icon: await imgPreload(icon9),
            name: "疑问",
            type: "一般",
            describe: "什么情况？",
            onKey: "什么情况？",
        }, {
            id: 10,
            icon: await imgPreload(icon10),
            name: "无语",
            type: "一般",
            describe: "家人们，谁懂啊",
            onKey: "无语子",
        }, {
            id: 11,
            icon: await imgPreload(icon11),
            name: "斜眼",
            type: "一般",
            describe: "这一天天的",
            onKey: "这一天天的",
        },
        {
            id: 12,
            icon: await imgPreload(icon12),
            name: "裂开",
            type: "不开心",
            describe: "我真的会谢！",
            onKey: "啊~，我裂开了！",
        },
        {
            id: 13,
            icon: await imgPreload(icon13),
            name: "脸绿",
            type: "不开心",
            describe: "还能说什么呢...",
            onKey: "还能说什么呢...",
        },
        {
            id: 14,
            icon: await imgPreload(icon14),
            name: "委屈",
            type: "不开心",
            describe: "委屈巴巴~",
            onKey: "委屈巴巴~",
        },
        {
            id: 15,
            icon: await imgPreload(icon15),
            name: "不开心",
            type: "不开心",
            describe: "朕emo了~",
            onKey: "朕emo了~",
        }
    ]
}

let config = {}

config.getById = async (id) => {
    return (await emoji()).find(f => f.id == id)
}

config.getByIndex = async (index) => {
    return (await emoji())[index]
}

config.getByType = async (type) => {
    return (await emoji()).find(f => f.type == type)
}

config.getByName = async (name) => {
    return (await emoji()).find(f => f.name == name)
}
config.getAll = async () => {
    const res = await emoji()
    return [...res]
}
export default config 