import { resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "hs-test",
  class: "h-full"
};
import emojiConfig from "@/config/emojiConfig.js";

import { onMounted } from 'vue';
export default {
  __name: 'App',
  setup(__props) {
    onMounted(async () => {
      // localStorage.setItem("active-eruda", false);
      console.log('hello 20241017');
      await emojiConfig.getById(1);
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createBlock(_Suspense, null, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      }))]);
    };
  }
};