import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3b45af1c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app-wrapper"
};
import * as themeVars from "@/config/theme/config-provider.json";
export default {
  __name: 'index',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_van_config_provider = _resolveComponent("van-config-provider");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_config_provider, {
        class: "content",
        "theme-vars": themeVars,
        "theme-vars-scope": "global"
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view, null, {
          default: _withCtx(({
            Component
          }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: _ctx.$route.name
          })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: _ctx.$route.name
          })) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      })]);
    };
  }
};