import { createRouter, createWebHashHistory } from "vue-router"
import ClientMonitor from 'skywalking-client-js'
import config from '../../package.json'
import routes from "./routes"
// import { useCachedViewStoreHook } from "@/store/modules/cachedView";
import NProgress from "@/utils/progress"
import setPageTitle from "@/utils/set-page-title"

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve) => {
      if (savedPosition) {
        const { top } = savedPosition
        window.scroll({
          top,
        })
      } else {
        resolve({ left: 0, top: 0 })
      }
    })
  },
})

router.beforeEach((to, from, next) => {
  console.log(from.fullPath, to.fullPath)
  NProgress.start()
  // 路由缓存
  // useCachedViewStoreHook().addCachedView(to);
  // 页面 title
  setPageTitle(to.meta.title)
  to.meta.title && window.ZWJSBridge && window.ZWJSBridge.setTitle({
    title: to.meta.title
  })
  next()
  window.microApp?.dispatch({ routePath: to.path })
})

router.afterEach(() => {
  NProgress.done()
  ClientMonitor.setPerformance({
    collector: 'https://skyapm-js.boran-tech.com',
    service: process.env.VUE_APP_WEBNAME,
    serviceVersion: config.version,//process.env.VUE_APP_VERSION,
    pagePath: location.href,
    useFmp: true
  })
})

export default router
