import Layout from "@/layout/index.vue"
const NotFound = () =>
    import(/* webpackChunkName: "404" */ "@/views/404/index.vue")

const routes = [
    {
        path: window.__MICRO_APP_BASE_ROUTE__ || "/",
        name: "root",
        component: Layout,
        redirect: { name: "Recorder" },
        children: [
            // {
            //     path: '/entry',
            //     name: 'entry',
            //     component: () => import("@/views/entry.vue")
            // },
            {
                path: 'stat',
                name: 'stat',
                component: () => import("@/views/stat.vue"),
                meta: {
                    title: "心情统计",
                    showTab: false,
                    showBack: true,
                }
            },
            {
                path: 'star',
                name: 'star',
                component: () => import("@/views/star.vue"),
                meta: {
                    title: "心情星球",
                    showTab: false,
                    showBack: true,
                }
            },
            {
                path: "/moodRecorder",
                name: "Recorder",
                component: () => import("@/views/moodRecorder/moodRecorder.vue"),
                meta: {
                    title: "我的心情日历",
                    showTab: false,
                    showBack: true,
                }
            },
            {
                path: "/moodRecorder/recorder",
                name: "RecorderEdit",
                component: () => import("@/views/moodRecorder/recorder.vue"),
                meta: {
                    title: "我的心情日历",
                    showTab: false,
                    showBack: true,
                }
            },
            {
                path: "/moodRecorder/myCalendar",
                name: "MyCalendar",
                component: () => import("@/views/moodRecorder/myCalendar.vue"),
                meta: {
                    title: "我的心情日历",
                    showTab: false,
                    showBack: true,
                }
            }, {
                path: "/calendar",
                name: "calendar",
                component: () => import("@/views/calendar/calendar.vue"),
                meta: {
                    title: "我的心情日历",
                    showTab: false,
                    showBack: true,
                }
            },
            {
                path: "/:pathMatch(.*)*",
                name: "NotFound",
                component: NotFound,
                meta: {
                    title: "404",
                    showTab: false,
                },
            }
        ],
    },
]

export default routes
